export function getExtensionFromUrl (url) {
  if (url) {
    const dirtUrl = new URL(url)
    const clearUrl = `${dirtUrl.pathname}`
    if (clearUrl.includes('.')) {
      return clearUrl.split('.').pop().toLowerCase()
    }
  }
  return undefined
}

export function getFormatBytes (bytes, locals = [], decimals = 2) {
  if (bytes === 0) return locals[0]

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = locals

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function getReadableFileSize (fileSize, i18nFileSizeDefinition) {
  if (typeof fileSize === 'number') {
    return getFormatBytes(fileSize, i18nFileSizeDefinition)
  }
  return false
}
