<template>
  <base-swiper
    is-button
    @button-click="handleSwiperButtonClick"
    :is-displayed="true">
      <base-swiper-slide v-for="item in list"
                         :key="item.id">
        <section-institutions-list-item
          :value="item"/>
      </base-swiper-slide>
  </base-swiper>
</template>

<script>
import BaseSwiper from '@/components/base/swiper/BaseSwiper'
import BaseSwiperSlide from '@/components/base/swiper/BaseSwiperSlide'
import SectionInstitutionsListItem from '@/components/section/institutions/SectionInstitutionsListItem'
export default {
  name: 'SectionInstitutionsList',
  components: { SectionInstitutionsListItem, BaseSwiperSlide, BaseSwiper },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    handleSwiperButtonClick () {
      console.log('test')
    }
  }
}
</script>
