<template>
    <base-details-list>
      <section-f-a-q-list-item v-for="(item, index) in list"
                               :key="index"
                               :value="item"/>
    </base-details-list>
</template>

<script>
import BaseDetailsList from '@/components/base/details/BaseDetailsList'
import SectionFAQListItem from '@/components/section/faq/SectionFAQListItem'
export default {
  name: 'SectionFAQList',
  components: { SectionFAQListItem, BaseDetailsList },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>
