<template>
  <base-card>
    <base-card-part-footer
      :place="getWorkAddress"
      :name="getWorkName"
      slot="card-footer"/>
    <persona-content :member="value.member"/>
  </base-card>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardPartFooter from '@/components/base/card/part/BaseCardPartFooter'
import PersonaContent from '@/components/misc/content/PersonaContent'
export default {
  name: 'SectionTeachersListItem',
  components: { PersonaContent, BaseCardPartFooter, BaseCard },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          member: {
            id: undefined,
            surname: undefined,
            firstname: undefined,
            middlename: undefined,
            logo: undefined,
            age: undefined,
            address: undefined,
            work: undefined,
            contacts: undefined
          }
        }
      }
    }
  },
  computed: {
    getWorkAddress () {
      return this.value.member?.work?.address?.short
    },
    getWorkName () {
      return this.value.member?.work?.value
    }
  }
}
</script>
