<template>
  <base-section class="section-map"
                :default-class="false"
  >
    <yandex-map :settings="settings"
                :coords="getUserCoords"
                :zoom="12"
                :use-object-manager="true"
                :scroll-zoom="false"
    >
      <section-map-marker-item
        v-for="item in getMarkersList"
        :key="item.id"
        :value="item"/>
    </yandex-map>
  </base-section>
</template>

<script>

import BaseSection from '@/components/base/section/BaseSection'
import SectionMapMarkerItem from '@/components/section/map/SectionMapMarkerItem'
import {
  GEO_COMPANIES_GETTER_GET_LIST,
  GEO_COMPANIES_ACTION_FETCH
} from '@/store/modules/geo/companies/geoCompanies.constant'
import { GEO_GETTER_GET_COORDS } from '@/store/modules/geo/geo.constant'
import { STORE_PATH_GEO, STORE_PATH_GEO_COMPANIES } from '@/store/store.list'
import { yandexMap } from 'vue-yandex-maps'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SectionMap',
  components: { SectionMapMarkerItem, BaseSection, yandexMap },
  data () {
    return {
      markerIcon: {
        layout: 'default#image',
        imageHref: '/assets/img/map-marker.png',
        imageSize: [66, 87],
        imageOffset: [-33, -87]
      },
      getCoordsWatcher: undefined,
      settings: {
        lang: 'ru_RU',
        coordorder: 'latlong'
        // version: '2.1'
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.checkDistance)
  },
  destroyed () {
    window.removeEventListener('scroll', this.checkDistance)

    if (typeof this.getCoordsWatcher !== 'undefined') {
      this.getCoordsWatcher()
    }
  },
  computed: {
    ...mapGetters(STORE_PATH_GEO_COMPANIES, [GEO_COMPANIES_GETTER_GET_LIST]),
    ...mapGetters(STORE_PATH_GEO, [GEO_GETTER_GET_COORDS]),
    getUserCoords () {
      return this[GEO_GETTER_GET_COORDS]
    },
    getMarkersList () {
      return this[GEO_COMPANIES_GETTER_GET_LIST]
    }
  },
  methods: {
    ...mapActions(STORE_PATH_GEO_COMPANIES, [GEO_COMPANIES_ACTION_FETCH]),
    async checkDistance () {
      if (window.scrollY >= this.$el.offsetTop - 2500) {
        this.getCoordsWatcher = this.$watch('getUserCoords', async (newValue) => {
          if (newValue) {
            await this.watcherGetItem()
          }
        })
        await this.watcherGetItem()
      }
    },
    async watcherGetItem () {
      await this.getItems()
      window.removeEventListener('scroll', this.checkDistance)
    },
    async getItems () {
      await this[GEO_COMPANIES_ACTION_FETCH](1)
    }
  }
}
</script>
