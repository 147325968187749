<template>
  <base-section class="section-teachers"
                :title="$t('PORTAL.SECTION.TEACHERS.HEADER')">
    <template v-slot:section-background>
      <base-background-item-oval is-shine-green/>
      <base-background-item-ellipse/>
    </template>

    <base-loader v-if="isLoading"/>
    <section-teachers-list v-else
                           :list="getList"
    />
  </base-section>
</template>

<script>
import BaseBackgroundItemEllipse from '@/components/base/background/item/BaseBackgroundItemEllipse'
import BaseBackgroundItemOval from '@/components/base/background/item/BaseBackgroundItemOval'
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseSection from '@/components/base/section/BaseSection'
import SectionTeachersList from '@/components/section/teachers/SectionTeachersList'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import { TEACHERS_GETTER_GET_LIMITED_LIST, TEACHERS_ACTION_FETCH } from '@/store/modules/teachers/teachers.constant'
import { STORE_PATH_TEACHERS } from '@/store/store.list'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SectionTeachers',
  components: {
    BaseSection,
    BaseLoader,
    SectionTeachersList,
    BaseBackgroundItemEllipse,
    BaseBackgroundItemOval
  },
  mounted () {
    this.getItems()
  },
  computed: {
    ...mapGetters(STORE_PATH_TEACHERS, {
      isLoading: COMMON_LOADER_GETTER_IS_LOADING,
      getList: TEACHERS_GETTER_GET_LIMITED_LIST
    })
  },
  methods: {
    ...mapActions(STORE_PATH_TEACHERS, [TEACHERS_ACTION_FETCH]),
    async getItems () {
      if (this.getList.length === 0) {
        await this[TEACHERS_ACTION_FETCH]()
      }
      return true
    }
  }
}
</script>
