<template>
<base-card>
  <base-card-part-title-logos
    :name="value.shortname"
    :description="value.fullname"
    :logo="getLogo"
    @click.native="handleClick"
  slot="card-title"
  />
  <base-card-part-logo
    v-if="getPhoto"
    :url="getPhoto"
    :alt="value.fullname"
    slot="card-logo"/>
  <base-card-part-footer
    :name="value.fullname"
    :place="getAddress"
    slot="card-footer"/>
  <persona-content :member="getContentItem"/>
</base-card>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardPartFooter from '@/components/base/card/part/BaseCardPartFooter'
import BaseCardPartLogo from '@/components/base/card/part/BaseCardPartLogo'
import BaseCardPartTitleLogos from '@/components/base/card/part/title/BaseCardPartTitleLogos'
import PersonaContent from '@/components/misc/content/PersonaContent'
import { C_EVENTS_MODAL_INSTITUTION_OPEN } from '@/lib/constant/c.events'
import { eventBus } from '@/lib/utils/eventBus'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'SectionInstitutionsListItem',
  components: { PersonaContent, BaseCardPartTitleLogos, BaseCardPartFooter, BaseCardPartLogo, BaseCard },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          shortname: undefined,
          fullname: undefined,
          logo: { url: undefined },
          photo: { url: undefined },
          address: { raw: undefined },
          contacts: [
            {
              type: undefined,
              value: undefined
            }
          ],
          headMember: {
            surname: undefined,
            firstname: undefined,
            middlename: undefined,
            position: undefined,
            logo: { url: undefined },
            contacts: [
              {
                type: undefined,
                value: undefined
              }
            ]
          }
        }
      }
    }
  },
  computed: {
    getAddress () {
      return this.value.address?.raw
    },
    getLogo () {
      return this.value.logo?.url
    },
    getPhoto () {
      return this.value.photo?.url
    },
    getContentItem () {
      const value = _cloneDeep(this.value)
      let content = value?.headMember
      const contentContacts = content?.contacts || []
      const contacts = value?.contacts || []
      if (!content) {
        content = {
          contacts
        }
      }
      contacts.push(...contentContacts)
      content.contacts = contacts
      return content
    }
  },
  methods: {
    handleClick () {
      eventBus.emit(C_EVENTS_MODAL_INSTITUTION_OPEN, this.value.id)
    }
  }
}
</script>
