<template>
  <h5><slot>{{title}}</slot></h5>
</template>

<script>
export default {
  name: 'BaseCardPartTitleSimple',
  props: {
    title: String
  }
}
</script>

<style scoped>

</style>
