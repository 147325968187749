<template>
   <div class="details-list">
     <slot></slot>
   </div>
</template>

<script>
export default {
  name: 'BaseDetailsList'
}
</script>
