<template>
    <base-details :title="value.TITLE" :content="value.MESSAGE"/>
</template>

<script>
import BaseDetails from '@/components/base/details/BaseDetails'
export default {
  name: 'SectionFAQListItem',
  components: { BaseDetails },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          TITLE: undefined,
          MESSAGE: undefined
        }
      }
    }

  }
}
</script>
