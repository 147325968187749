<template>
<base-swiper
  is-button
  :is-displayed="isDisplayed"
  @button-click="handleSwiperButtonClick">
  <base-swiper-slide
    v-for="item in list"
    :key="item.id">
    <section-reviews-list-item
      :value="item"/>
  </base-swiper-slide>
</base-swiper>
</template>

<script>
import BaseSwiper from '@/components/base/swiper/BaseSwiper'
import BaseSwiperSlide from '@/components/base/swiper/BaseSwiperSlide'
import SectionReviewsListItem from '@/components/section/reviews/SectionReviewsListItem'
export default {
  name: 'SectionReviewsList',
  components: { SectionReviewsListItem, BaseSwiperSlide, BaseSwiper },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    isDisplayed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSwiperButtonClick () {
      console.log('reviewsButtonClick')
    }
  }
}
</script>
