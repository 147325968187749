<template>
    <div class="documents-content">
        <div class="documents-content__list">
          <base-document v-for="document in list"
                         :key="document.id"
                         :item="document"/>
        </div>
    </div>
</template>

<script>
import BaseDocument from '@/components/base/document/BaseDocument'
export default {
  name: 'DocumentsContent',
  components: { BaseDocument },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
