<template>
  <base-section class="section-promo"
                :title="$t('PORTAL.SECTION.PROMO.HEADER')">
    <template v-slot:section-background>
      <base-background-item-oval is-light-blue class="bg__oval--center"/>
      <base-background-item-ellipse/>
      <base-background-item-oval is-light-blue class="bg__oval--right"/>
      <base-background-item-triangle is-shine-blue/>
      <base-background-item-blot/>
    </template>

    <base-loader v-if="isLoading"/>
    <section-promo-list v-else
                        :list="getList"/>
  </base-section>

</template>

<script>
import BaseBackgroundItemBlot from '@/components/base/background/item/BaseBackgroundItemBlot'
import BaseBackgroundItemEllipse from '@/components/base/background/item/BaseBackgroundItemEllipse'
import BaseBackgroundItemOval from '@/components/base/background/item/BaseBackgroundItemOval'
import BaseBackgroundItemTriangle from '@/components/base/background/item/BaseBackgroundItemTriangle'
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseSection from '@/components/base/section/BaseSection'
import SectionPromoList from '@/components/section/promo/SectionPromoList'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import { PROMO_GETTER_GET_LIMITED_LIST, PROMO_ACTION_FETCH } from '@/store/modules/promo/promo.constant'
import { STORE_PATH_PROMO } from '@/store/store.list'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SectionPromo',
  components: {
    BaseLoader,
    SectionPromoList,
    BaseSection,
    BaseBackgroundItemBlot,
    BaseBackgroundItemTriangle,
    BaseBackgroundItemEllipse,
    BaseBackgroundItemOval
  },
  mounted () {
    this.getItems()
  },
  computed: {
    ...mapGetters(STORE_PATH_PROMO, {
      isLoading: COMMON_LOADER_GETTER_IS_LOADING,
      getList: PROMO_GETTER_GET_LIMITED_LIST
    })
  },
  methods: {
    ...mapActions(STORE_PATH_PROMO, [PROMO_ACTION_FETCH]),
    async getItems () {
      if (this.getList.length === 0) {
        await this[PROMO_ACTION_FETCH]()
      }
      return true
    }
  }
}
</script>
