<template>
  <base-svg-icon
    :name="getIconName"
    :default-icon="getDefaultIcon"
    :title="title"/>
</template>

<script>

import { getMaterialIconName, getDefaultMaterialIconName } from '@/lib/utils/material/utilsMaterial'
import BaseSvgIcon from '../svgIcon/BaseSvgIcon'

export default {
  name: 'BaseIconFile',
  components: { BaseSvgIcon },
  data () {
    return {
      svgItem: ''
    }
  },
  props: {
    name: {
      type: String
    },
    title: {
      type: String,
      default: ''
    },
    dataType: {
      type: [String, undefined],
      required: true
    }
  },
  computed: {
    getDefaultIcon () {
      if (this.dataType) {
        return getMaterialIconName(getDefaultMaterialIconName(this.dataType))
      }
      return ''
    },
    getIconName () {
      return getMaterialIconName(this.name)
    }
  }
}
</script>
