<template>
  <section class="main">
    <section-main-backgrounds
      :list="getBgList"
      :loading="isBgLoading"
    />
    <div class="content main__content">
      <div class="content__offset"></div>
      <base-button
        class="content__item main__button"
        icon="school"
        :to="{name: $options.ROUTE_ARTICLE_ITEM, params: {type: 'promo', id: 1}}"
      >
        <div class="main__button-content">
          <h4 class="title">{{$t('PORTAL.MAIN.BUTTONS.CREATE-SITE.INSTITUTIONS.TITLE')}}</h4>
          <span class="description">{{$t('PORTAL.MAIN.BUTTONS.CREATE-SITE.INSTITUTIONS.DESCRIPTION')}}</span>
        </div>
      </base-button>
      <base-button
        class="content__item main__button"
        icon="teacher-lecture"
        :to="{name: $options.ROUTE_PROMOTION_KINDEREDU}"
      >
        <div class="main__button-content">
          <h4 class="title">{{$t('PORTAL.MAIN.BUTTONS.CREATE-SITE.TEACHER.TITLE')}}</h4>
          <span class="description">{{$t('PORTAL.MAIN.BUTTONS.CREATE-SITE.TEACHER.DESCRIPTION')}}</span>
        </div>
      </base-button>
    </div>
  </section>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import SectionMainBackgrounds from '@/components/section/main/SectionMainBackgrounds'
import { C_ROUTER_NAME_ARTICLE_ITEM, C_ROUTER_NAME_PROMOTION_PAGE_KINDEREDU } from '@/lib/constant/c.router'
import {
  BACKGROUNDS_MAIN_GETTER_GET_MAIN_SLIDE_BG_LIST,
  BACKGROUNDS_MAIN_ACTION_FETCH_MAIN_SLIDE_BG
} from '@/store/modules/backgrounds/main/backgroundsMain.constant'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import { STORE_PATH_BACKGROUNDS_MAIN } from '@/store/store.list'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SectionMain',
  ROUTE_ARTICLE_ITEM: C_ROUTER_NAME_ARTICLE_ITEM,
  ROUTE_PROMOTION_KINDEREDU: C_ROUTER_NAME_PROMOTION_PAGE_KINDEREDU,
  components: { SectionMainBackgrounds, BaseButton },
  async mounted () {
    await this.getBgItems()
  },
  computed: {
    ...mapGetters(STORE_PATH_BACKGROUNDS_MAIN, [
      COMMON_LOADER_GETTER_IS_LOADING,
      BACKGROUNDS_MAIN_GETTER_GET_MAIN_SLIDE_BG_LIST]),
    getBgList () {
      return this[BACKGROUNDS_MAIN_GETTER_GET_MAIN_SLIDE_BG_LIST]
    },
    isBgLoading () {
      return this[COMMON_LOADER_GETTER_IS_LOADING]
    }
  },
  methods: {
    ...mapActions(STORE_PATH_BACKGROUNDS_MAIN, [BACKGROUNDS_MAIN_ACTION_FETCH_MAIN_SLIDE_BG]),
    async getBgItems () {
      await this[BACKGROUNDS_MAIN_ACTION_FETCH_MAIN_SLIDE_BG]()
    }
  }

}
</script>
