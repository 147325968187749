<template>
    <base-swiper
      is-button
      :is-displayed="isDisplayed"
      @button-click="handleSwiperButtonClick">
      <base-swiper-slide
        v-for="item in list"
        :key="item.company.id">
        <section-documents-list-item
          :value="item"/>
      </base-swiper-slide>
    </base-swiper>
</template>

<script>
import BaseSwiper from '@/components/base/swiper/BaseSwiper'
import BaseSwiperSlide from '@/components/base/swiper/BaseSwiperSlide'
import SectionDocumentsListItem from '@/components/section/documents/SectionDocumentsListItem'
export default {
  name: 'SectionDocumentsList',
  components: { SectionDocumentsListItem, BaseSwiperSlide, BaseSwiper },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    isDisplayed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSwiperButtonClick () {
      console.log('test')
    }
  }
}
</script>
