<template>
  <ymap-marker
    marker-type="placemark"
    :marker-id="value.id"
    :coords="getCoords"
    :icon="markerIcon"
    :cluster-name="getClusterName"
  >
    <div class="map-balloon" slot="balloon">
      <h6>{{value.name}}</h6>
      <span class="map__balloon-address">
      {{getAddress}}
    </span>
      <base-button v-if="getUrl"
                   :href="getUrl"
                   target="_blank"
                   bordered>
        {{$t('PORTAL.SECTION.MAP.BALLOON.BUTTON.VALUE')}}
      </base-button>
    </div>
  </ymap-marker>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import { ymapMarker } from 'vue-yandex-maps'

export default {
  name: 'SectionMapMarkerItem',
  components: { BaseButton, ymapMarker },
  data () {
    return {
      markerIcon: {
        layout: 'default#image',
        imageHref: '/assets/img/map-marker.png',
        imageSize: [66, 87],
        imageOffset: [-33, -87]
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          name: undefined,
          address: {
            lon: undefined,
            lat: undefined,
            raw: undefined,
            regionId: undefined
          },
          contacts: [
            {
              id: undefined,
              type: undefined,
              value: undefined
            }
          ]
        }
      }
    }
  },
  computed: {
    getAddress () {
      return this.value?.address?.raw
    },
    getCoords () {
      return [this.value?.address?.lat, this.value?.address?.lon]
    },
    getClusterName () {
      return this.value?.address?.regionId || 'default'
    },
    getUrl () {
      const contacts = this.value?.contacts
      if (!contacts || !Array.isArray(contacts)) {
        return false
      }
      return contacts[0].value
    }
  }
}
</script>
