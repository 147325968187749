import {
  C_MATERIAL_DATA_TYPE,
  C_MATERIAL_AUDIO_ICON,
  C_MATERIAL_ALBUM_ICON,
  C_MATERIAL_ARTICLE_ICON, C_MATERIAL_URL_IFRAME, C_MATERIAL_URL_ICON, C_MATERIAL_VIDEO_ICON, C_MATERIAL_DEFAULT_ICON
} from '../../constant/c.material'
import { getExtensionFromUrl } from '../fileFunctionaly'

export function getMaterialIconName (name) {
  return `file/${name}`
}
export function getDefaultMaterialIconName (dataType) {
  switch (dataType) {
    case C_MATERIAL_DATA_TYPE.audio:
      return C_MATERIAL_AUDIO_ICON
    case C_MATERIAL_DATA_TYPE.album:
      return C_MATERIAL_ALBUM_ICON
    case C_MATERIAL_DATA_TYPE.article:
      return C_MATERIAL_ARTICLE_ICON
    case C_MATERIAL_DATA_TYPE.iframe:
      return C_MATERIAL_URL_IFRAME
    case C_MATERIAL_DATA_TYPE.url:
      return C_MATERIAL_URL_ICON
    case C_MATERIAL_DATA_TYPE.video:
      return C_MATERIAL_VIDEO_ICON
    default:
      return C_MATERIAL_DEFAULT_ICON
  }
}

export function getComputedMaterialIcon (dataType, fileUrl) {
  const getExt = getExtensionFromUrl(fileUrl)
  switch (dataType) {
    case C_MATERIAL_DATA_TYPE.document:
      if (typeof getExt !== 'undefined') {
        return getExt
      }
      break
    case C_MATERIAL_DATA_TYPE.audio: {
      if (typeof getExt !== 'undefined') {
        return getExt
      }
      return C_MATERIAL_AUDIO_ICON
    }
    case C_MATERIAL_DATA_TYPE.album:
      return C_MATERIAL_ALBUM_ICON
    case C_MATERIAL_DATA_TYPE.article:
      return C_MATERIAL_ARTICLE_ICON
    case C_MATERIAL_DATA_TYPE.iframe:
      return C_MATERIAL_URL_IFRAME
    case C_MATERIAL_DATA_TYPE.url:
      return C_MATERIAL_URL_ICON
    case C_MATERIAL_DATA_TYPE.video:
      return C_MATERIAL_VIDEO_ICON
    default:
      return C_MATERIAL_DEFAULT_ICON
  }
  return C_MATERIAL_DEFAULT_ICON
}
