import {
  SYSTEM_GETTER_CHECK_RESOLUTION_TYPE
} from '@/store/modules/system/system.constant'
import { STORE_PATH_SYSTEM } from '@/store/store.list'
import { mapGetters } from 'vuex'

export default {
  /**
   * @property {function(Number): boolean} _mixinSystem_checkResolution
   */
  computed: {
    ...mapGetters(STORE_PATH_SYSTEM, {
      _mixinSystem_checkResolution: SYSTEM_GETTER_CHECK_RESOLUTION_TYPE
    }),
    mixinSystem_is1240 () {
      return this._mixinSystem_checkResolution(1240)
    },
    mixinSystem_is720 () {
      return this._mixinSystem_checkResolution(720)
    }
  },
  methods: {
    mixinSystem_isSet (width) {
      return this._mixinSystem_checkResolution(width)
    }
  }
}
