<template>
  <base-card is-vertical>
    <base-card-part-title-content
      slot="card-title"
      :title="'lorem ipsum dollar naller'">
      <template v-slot:description>
        <base-icon-text :icon="$t('PORTAL.SECTION.PROMO.CARD.TITLE.DESCRIPTION.ICON')">{{getTitleDescription}}</base-icon-text>
      </template>
    </base-card-part-title-content>
    <base-card-part-logo
      v-if="getLogo"
      :url="getLogo"
      slot="card-logo"
    ></base-card-part-logo>
    <div class="promo_content">
      <span>{{value.description}}</span>
      <base-button
        href="https://yandex.ru"
        :value="$t('PORTAL.BUTTONS.SHOW-MORE.VALUE')"
        bordered></base-button>
    </div>
  </base-card>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardPartLogo from '@/components/base/card/part/BaseCardPartLogo'
import BaseCardPartTitleContent from '@/components/base/card/part/title/BaseCardPartTitleContent'
import BaseIconText from '@/components/base/iconText/BaseIconText'
import { C_LOCALS_DATE_FORMAT_TYPE } from '@/lib/constant/c.locales'
export default {
  name: 'SectionPromoListItem',
  components: { BaseButton, BaseIconText, BaseCardPartLogo, BaseCardPartTitleContent, BaseCard },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          logo: { url: undefined },
          title: undefined,
          description: undefined,
          dtFrom: undefined,
          dtTo: undefined
        }
      }
    }
  },
  computed: {
    getTitleDescription () {
      const dateStart = this.getTime(this.value?.dtFrom)
      const dateEnd = this.getTime(this.value?.dtTo)
      return this.$t('PORTAL.SECTION.PROMO.CARD.TITLE.DESCRIPTION.VALUE', { dateStart, dateEnd })
    },
    getLogo () {
      return this.value.logo?.url
    }
  },
  methods: {
    getTime (value) {
      return this.$d(new Date(value), C_LOCALS_DATE_FORMAT_TYPE.numericShort)
    }
  }
}
</script>
