<template>
    <div class="tabs__content-item">
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'BaseTabItem'
}
</script>
