<template>
  <fragment>
    <component :is="getTemplateName"
               :key="value.id"
               :value="value"
    ></component>
  </fragment>
</template>

<script>
import { REVIEWS_GETTER_GET_SELECTED_TYPE, SECTION_REVIEWS_TYPE } from '@/store/modules/reviews/reviews.constant'
import { STORE_PATH_REVIEWS } from '@/store/store.list'
import { mapGetters } from 'vuex'

export default {
  name: 'SectionReviewsListItem',
  components: {
    [SECTION_REVIEWS_TYPE.INSTITUTIONS_ABOUT_SERVICE]: () =>
      import('@/components/section/reviews/type/ReviewsItemAboutUs'),
    [SECTION_REVIEWS_TYPE.TEACHERS_ABOUT_SERVICE]: () =>
      import('@/components/section/reviews/type/ReviewsItemAboutUs'),
    [SECTION_REVIEWS_TYPE.PARENTS_ABOUT_INSTITUTIONS]: () => import('@/components/section/reviews/type/ReviewsItemAboutInstitutions')
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(STORE_PATH_REVIEWS, [REVIEWS_GETTER_GET_SELECTED_TYPE]),
    getTemplateName () {
      return this[REVIEWS_GETTER_GET_SELECTED_TYPE]
    }
  }
}
</script>
