<template>
  <fragment>
    <section-main/>
    <section-events/>
    <section-documents/>
    <section-institutions/>
    <section-teachers/>
    <section-reviews/>
    <section-promo/>
    <section-about-us/>
    <section-f-a-q/>
    <section-map/>
  </fragment>
</template>

<script>
import SectionAboutUs from '@/components/section/SectionAboutUs'
import SectionDocuments from '@/components/section/SectionDocuments'
import SectionEvents from '@/components/section/SectionEvents'
import SectionFAQ from '@/components/section/SectionFAQ'
import SectionInstitutions from '@/components/section/SectionInstitutions'
import SectionMain from '@/components/section/SectionMain'
import SectionMap from '@/components/section/SectionMap'
import SectionPromo from '@/components/section/SectionPromo'
import SectionReviews from '@/components/section/SectionReviews'
import SectionTeachers from '@/components/section/SectionTeachers'

export default {
  name: 'ViewsIndex',
  components: {
    SectionMain,
    SectionMap,
    SectionFAQ,
    SectionAboutUs,
    SectionPromo,
    SectionReviews,
    SectionTeachers,
    SectionInstitutions,
    SectionDocuments,
    SectionEvents
  }
}
</script>
