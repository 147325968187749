<template>
  <base-card
    pointed
  >
    <base-card-part-title-simple
      :title="value.company.shortname"
      slot="card-title"/>
    <base-card-part-logo
      v-if="getLogo"
      :url="getLogo"
      :alt="value.title"
      slot="card-logo"/>
    <base-card-part-footer
      :name="value.company.fullname"
      :place="getAddress"
      slot="card-footer"/>
    <plain-content
      :is-wide-description="!getLogo"
      :time="getTime"
      :count-view="value.views"
      :title="value.title"
      :description="getDescription"/>
  </base-card>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardPartFooter from '@/components/base/card/part/BaseCardPartFooter'
import BaseCardPartLogo from '@/components/base/card/part/BaseCardPartLogo'
import BaseCardPartTitleSimple from '@/components/base/card/part/title/BaseCardPartTitleSimple'
import PlainContent from '@/components/misc/content/PlainContent'

export default {
  name: 'SectionEventListItem',
  components: {
    PlainContent,
    BaseCardPartFooter,
    BaseCardPartLogo,
    BaseCardPartTitleSimple,
    BaseCard
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {
        return {
          company: {
            shortname: undefined,
            fullname: undefined,
            address: { raw: undefined }
          },
          event: {
            id: undefined,
            logo: { url: undefined },
            title: undefined,
            description: undefined,
            proto: {
              insert:
                  { value: undefined }
            },
            views: undefined
          }
        }
      }
    }
  },
  computed: {
    getTime () {
      // console.log(this.value)
      return this.value.proto?.dateTime
    },
    getDescription () {
      const element = document.createElement('div')
      element.innerHTML = this.value.description
      return element.textContent || element.innerText
    },
    getAddress () {
      return this.value.company?.address?.raw
    },
    getLogo () {
      return this.value.logo?.url
    }
  }
}
</script>

<style scoped>

</style>
