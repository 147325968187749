<template>
    <base-section class="section-FAQ"
                  :default-class="false"
                  :title="$t('PORTAL.SECTION.FAQ.TITLE')">
      <template v-slot:section-background>
        <base-background-item-ellipse/>
      </template>
        <section-f-a-q-list
          :list="$t('PORTAL.SECTION.FAQ.MESSAGES')"/>
    </base-section>
</template>

<script>
import BaseBackgroundItemEllipse from '@/components/base/background/item/BaseBackgroundItemEllipse'
import BaseSection from '@/components/base/section/BaseSection'
import SectionFAQList from '@/components/section/faq/SectionFAQList'
export default {
  name: 'SectionFAQ',
  components: { SectionFAQList, BaseBackgroundItemEllipse, BaseSection }
}
</script>
