export const C_MATERIAL_DEFAULT_ICON = 'default'
export const C_MATERIAL_ALBUM_ICON = 'album'
export const C_MATERIAL_ARTICLE_ICON = 'article'
export const C_MATERIAL_AUDIO_ICON = 'audio'
export const C_MATERIAL_URL_ICON = 'url'
export const C_MATERIAL_URL_IFRAME = 'iframe'
export const C_MATERIAL_VIDEO_ICON = 'video'

export const C_MATERIAL_DATA_TYPE = {
  document: 'document',
  audio: 'audio',
  article: 'article',
  iframe: 'iframe',
  album: 'album',
  video: 'video',
  url: 'url'

}
