<template>
  <fragment>
    <base-card-divider v-if="isDivide"/>
    <div class="card__footer">
      <div class="card__footer-name"><slot name="name">{{name}}</slot></div>
      <address  v-show="place"
                class="card__footer-place">
        <slot name="place">{{place}}</slot>
      </address>
    </div>
  </fragment>
</template>

<script>
import BaseCardDivider from '@/components/base/card/BaseCardDivider'
export default {
  name: 'BaseCardPartFooter',
  components: { BaseCardDivider },
  props: {
    isDivide: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ''
    },
    place: {
      default: ''
    }
  }
}
</script>
