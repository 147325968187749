<template>
<div class="card-title__logos">
  <div class="logo" v-show="getLogo">
    <img :src="getLogo" :alt="name"/>
  </div>
  <base-card-part-title-content
    :title="name"
    :description="description"/>
</div>
</template>

<script>
import BaseCardPartTitleContent from '@/components/base/card/part/title/BaseCardPartTitleContent'
export default {
  name: 'BaseCardPartTitleLogos',
  components: { BaseCardPartTitleContent },
  props: {
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    logo: {
      type: [String, undefined],
      default: undefined
    }
  },
  computed: {
    getLogo () {
      return this.logo
    }
  }
}
</script>
