<template>
  <swiper :options="getOptions"
          class="base-swiper"
          :class="{'swiper--loaded': isDisplayed}"
          :auto-update="false"
          :auto-destroy="true"
          :ref="$options.name"
  >
    <slot></slot>
    <div class="swiper__bottom" slot="pagination">
      <div>
      <base-button
        v-show="isButton"
        class="swiper__button"
        @click.native.prevent="handleButtonClick"
        :value="buttonCaption"
        bordered></base-button>
      </div>
      <div class="swiper__pagination swiper-pagination-clickable"></div>
    </div>

  </swiper>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/swiper.esm.js'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

import 'swiper/swiper-bundle.min.css'

SwiperClass.use([Pagination, Navigation])
const { Swiper } = getAwesomeSwiper(SwiperClass)

const directive = getAwesomeSwiper(SwiperClass)
export default {
  name: 'BaseSwiper',
  components: {
    BaseButton,
    Swiper
  },
  directives: {
    swiper: directive
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 31,
        pagination: {
          clickable: true,
          el: '.swiper__bottom>.swiper__pagination'
        },
        breakpoints: {
          1280: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 31
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 31
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 10
          }
        }
      }
    }
  },
  props: {
    isDisplayed: {
      type: Boolean,
      default: false
    },
    isButton: {
      type: Boolean,
      default: false
    },
    buttonValue: {
      type: String,
      default: ''
    }
  },
  watch: {
    isDisplayed (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.swiper.update()
        })
      }
    }
  },
  computed: {
    buttonCaption () {
      return this.buttonValue || this.$t('PORTAL.BUTTONS.SWIPER-BOTTOM.VALUE')
    },
    swiper () {
      return this.$refs[this.$options.name].$swiper
    },
    getOptions () {
      return this.swiperOptions
    }
  },
  methods: {
    handleButtonClick () {
      this.$emit('button-click')
    }
  }
}
</script>
