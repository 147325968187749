<template>
  <div class="tabs"
    :class="{'tabs--native': isNative}"
  >
    <div class="tabs__navigation">
      <div class="tabs-navigation__list">
        <div class="tabs-navigation__active-line"
             :style="activeStyle"
        />
          <span v-for="(item, index) in tabsTap"
                class="tabs-navigation__item"
                :class="{ 'tabs-navigation__item--active': currentTab === item}"
                @click="setSelected(item)"
                :key="index"
                :ref="item">
            <span>{{getNavigationName(index)}}</span>
          </span>
        </div>
      </div>
    <div class="tabs__content">
      <slot></slot>
    </div>
  </div>

</template>

<script>
import mixinSystem from '@/components/mixin/mixinSystem'
import _template from 'lodash/template'
const EVENT_TAB_SELECTED = 'setSelected'

export default {
  name: 'BaseTabs',
  mixins: [mixinSystem],
  model: {
    prop: 'currentTab',
    event: EVENT_TAB_SELECTED
  },
  data () {
    return {
      activeLineWidth: 0,
      activeLineOffset: 0
    }
  },
  props: {
    i18nNavigationItemTemplate: {
      type: String,
      default: '',
      required: true
    },
    nativeWidthEnabled: {
      type: Number,
      default: 1240
    },
    tabsTap: {
      type: Object,
      defaultValue: () => {
        return {}
      }
    },
    currentTab: {
      type: String,
      defaultValue: ''
    }
  },
  computed: {
    isNative () {
      return this.mixinSystem_isSet(this.nativeWidthEnabled)
    },
    activeStyle () {
      return {
        width: `${this.activeLineWidth}px`,
        transform: `translateX(${this.activeLineOffset}px)`
      }
    }
  },
  methods: {
    getNavigationName (item) {
      const template = _template(this.i18nNavigationItemTemplate)
      return this.$t(template({ item }))
    },
    moveActiveLine (newValue) {
      if (this.isNative) return
      if (!this.currentTab) return
      if (!this.$refs || !this.$refs[newValue] || !this.$refs[newValue][0]) return

      const element = this.$refs[newValue][0]
      this.activeLineWidth = element.clientWidth + 30
      this.activeLineOffset = element.offsetLeft - 15
    },
    setSelected (value) {
      this.$emit(EVENT_TAB_SELECTED, value)
      this.moveActiveLine(value)
    }
  },
  created () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.moveActiveLine(this.currentTab)
      }, 50)
    })
  }
}
</script>
