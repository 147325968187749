<template>
    <base-section
      class="section-about-us"
    >
      <template v-slot:section-background>
        <base-background-item-blot/>
        <base-background-item-oval is-light-blue/>
      </template>

      <div class="about-us__container">
        <div class="logo">
          <img src="/assets/img/about-us.png">
        </div>
        <div class="content">
          <h3>{{$t('PORTAL.SECTION.ABOUT-US.HEADER')}}</h3>
          <p>{{$t('PORTAL.SECTION.ABOUT-US.VALUE')}}</p>
        </div>
      </div>
    </base-section>
</template>

<script>
import BaseBackgroundItemBlot from '@/components/base/background/item/BaseBackgroundItemBlot'
import BaseBackgroundItemOval from '@/components/base/background/item/BaseBackgroundItemOval'
import BaseSection from '@/components/base/section/BaseSection'
export default {
  name: 'SectionAboutUs',
  components: { BaseBackgroundItemOval, BaseBackgroundItemBlot, BaseSection }
}
</script>
