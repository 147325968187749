<template>
  <base-section class="section-institutions"
  :title="$t('PORTAL.SECTION.INSTITUTIONS.HEADER')">
    <template v-slot:section-background>
      <base-background-item-astro class="astro"/>
      <base-background-item-oval class="planet-one"/>
      <base-background-item-oval class="planet-two" is-light-blue/>
      <base-background-item-oval class="planet-three" is-green/>
    </template>

    <base-loader v-if="isLoading"/>
    <section-institutions-list v-else
                               :list="getList"
    />
    <modal-institution/>
  </base-section>
</template>

<script>
import BaseBackground from '@/components/base/background/BaseBackground'
import BaseBackgroundItemAstro from '@/components/base/background/item/BaseBackgroundItemAstro'
import BaseBackgroundItemOval from '@/components/base/background/item/BaseBackgroundItemOval'
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseSection from '@/components/base/section/BaseSection'
import ModalInstitution from '@/components/modals/ModalInstitution'
import SectionInstitutionsList from '@/components/section/institutions/SectionInstitutionsList'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  INSTITUTIONS_GETTER_GET_LIMITED_LIST,
  INSTITUTIONS_ACTION_FETCH
} from '@/store/modules/institutions/institutions.constant'
import { STORE_PATH_INSTITUTIONS } from '@/store/store.list'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SectionInstitutions',
  components: {
    ModalInstitution,
    BaseSection,
    // eslint-disable-next-line vue/no-unused-components
    BaseBackground,
    BaseLoader,
    SectionInstitutionsList,
    BaseBackgroundItemOval,
    BaseBackgroundItemAstro
  },
  mounted () {
    this.getItems()
    this.$modal.show('modal')
  },
  computed: {
    ...mapGetters(STORE_PATH_INSTITUTIONS, {
      isLoading: COMMON_LOADER_GETTER_IS_LOADING,
      getList: INSTITUTIONS_GETTER_GET_LIMITED_LIST
    })
  },
  methods: {
    ...mapActions(STORE_PATH_INSTITUTIONS, [INSTITUTIONS_ACTION_FETCH]),
    async getItems () {
      if (this.getList.length === 0) {
        await this[INSTITUTIONS_ACTION_FETCH]()
      }
      return true
    }
  }
}
</script>
