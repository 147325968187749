<template>
  <base-section :title="$t('PORTAL.SECTION.EVENTS.HEADER')" class="section-events">
    <template v-slot:section-background>
      <base-background-item-triangle/>
        <base-background-item-square
          class="bg__square--left"
          is-green/>
        <base-background-item-square
          class="bg__square--right"
        />
      <base-background-item-blot/>
    </template>

    <base-tabs
      :tabs-tap="tabTaps"
      v-model="currentTab"
      i18n-navigation-item-template="PORTAL.SECTION.EVENTS.TAB.${item}.VALUE"
    >

      <!--        <slide-x-right-transition-->
      <!--          tag="div"-->
      <!--          group>-->
      <base-tab-item class="events__institutions"
                     key="event_institutions"
                     v-show="currentTab === tabTaps.EDUCATION_INSTITUTIONS">
        <base-loader v-if="currentLoading(tabTaps.EDUCATION_INSTITUTIONS)" />
        <section-event-list
          v-else
          :is-displayed="currentTab===tabTaps.EDUCATION_INSTITUTIONS"
          :list="getList"/>
      </base-tab-item>
      <base-tab-item class="events__teacher"
                     key="event-Teacher"
                     v-show="currentTab === tabTaps.TEACHERS">
        <base-loader v-if="currentLoading(tabTaps.TEACHERS)"/>
        <section-event-list
          v-else
          :is-displayed="currentTab === tabTaps.TEACHERS"
          :list="getList"/>
      </base-tab-item>

      <!--        </slide-x-right-transition>-->
    </base-tabs>

  </base-section>

</template>

<script>
import BaseBackgroundItemBlot from '@/components/base/background/item/BaseBackgroundItemBlot'
import BaseBackgroundItemSquare from '@/components/base/background/item/BaseBackgroundItemSquare'
import BaseBackgroundItemTriangle from '@/components/base/background/item/BaseBackgroundItemTriangle'
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseSection from '@/components/base/section/BaseSection'
import BaseTabItem from '@/components/base/tabs/BaseTabItem'
import BaseTabs from '@/components/base/tabs/BaseTabs'
import SectionEventList from '@/components/section/events/SectionEventList'
import {
  SECTION_EVENT_TYPE,
  EVENTS_ACTION_FETCH,
  EVENTS_MUTATION_SET_SELECTED_TYPE,
  EVENTS_GETTER_GET_SECTION_LOADER,
  EVENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE
} from '@/store/modules/events/events.constant'
// import { SlideXRightTransition } from 'vue2-transitions'
import { STORE_PATH_EVENTS } from '@/store/store.list'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'SectionEvents',
  components: {
    BaseSection,
    BaseTabItem,
    BaseBackgroundItemBlot,
    BaseBackgroundItemSquare,
    BaseBackgroundItemTriangle,
    BaseLoader,
    SectionEventList,
    BaseTabs
    // SlideXRightTransition
  },
  data () {
    return {
      tabTaps: SECTION_EVENT_TYPE,
      currentTab: SECTION_EVENT_TYPE.EDUCATION_INSTITUTIONS
    }
  },
  computed: {
    ...mapGetters(STORE_PATH_EVENTS, {
      getList: EVENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE,
      currentLoading: EVENTS_GETTER_GET_SECTION_LOADER
    })
  },
  watch: {
    currentTab (newValue) {
      this[EVENTS_MUTATION_SET_SELECTED_TYPE](newValue)
      this.getItems(newValue)
    }
  },
  methods: {
    ...mapMutations(STORE_PATH_EVENTS, [EVENTS_MUTATION_SET_SELECTED_TYPE]),
    ...mapActions(STORE_PATH_EVENTS, [EVENTS_ACTION_FETCH]),
    async getItems (type) {
      if (this.getList.length === 0) {
        await this[EVENTS_ACTION_FETCH]({
          type,
          page: 1
        })
        return true
      }
    }
  },
  mounted () {
    this.getItems(this.currentTab)
  }
}
</script>
