<template>
  <base-card>
    <base-card-part-title-simple
      :title="value.company.shortname"
      slot="card-title"/>
    <base-card-part-footer
      :name="value.company.fullname"
      :place="getAddress"
      slot="card-footer"/>

    <documents-content
        :list="getDocuments"
    />
  </base-card>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardPartFooter from '@/components/base/card/part/BaseCardPartFooter'
import BaseCardPartTitleSimple from '@/components/base/card/part/title/BaseCardPartTitleSimple'
import DocumentsContent from '@/components/misc/content/DocumentsContent'

export default {
  name: 'SectionDocumentsListItem',
  components: {
    DocumentsContent,
    BaseCardPartFooter,
    BaseCardPartTitleSimple,
    BaseCard
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {
        return {
          company: {
            id: undefined,
            shortname: undefined,
            fullname: undefined,
            address: { raw: undefined }
          },
          documents: []
        }
      }
    }
  },
  computed: {
    getDocuments () {
      return this.value.documents || []
    },
    getTime () {
      return this.value.event?.proto?.insert?.value
    },
    getDescription () {
      const element = document.createElement('div')
      element.innerHTML = this.value.event.description
      return element.textContent || element.innerText
    },
    getAddress () {
      return this.value.company?.address?.raw
    }
  }
}
</script>
