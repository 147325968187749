<template>
  <base-card-list is-column>
    <section-promo-list-item v-for="item in list"
                             :key="item.id"
                             :value="item"/>
  </base-card-list>
</template>

<script>
import BaseCardList from '@/components/base/card/BaseCardList'
import SectionPromoListItem from '@/components/section/promo/SectionPromoListItem'

export default {
  name: 'SectionPromoList',
  components: {
    BaseCardList,
    SectionPromoListItem
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>
