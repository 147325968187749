<template>
  <base-swiper
    is-button
    @button-click="handleSwiperButtonClick"
    :is-displayed="isDisplayed"
  >
    <base-swiper-slide
      v-for="item in list"
      :key="item.id">
      <SectionEventListItem
        :value="item"
      />
    </base-swiper-slide>
  </base-swiper>
</template>

<script>
import BaseSwiper from '@/components/base/swiper/BaseSwiper'
import BaseSwiperSlide from '@/components/base/swiper/BaseSwiperSlide'
import SectionEventListItem from '@/components/section/events/SectionEventListItem'

export default {
  name: 'SectionEventList',
  components: {
    BaseSwiper,
    SectionEventListItem,
    BaseSwiperSlide
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    isDisplayed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSwiperButtonClick () {
      console.log('test')
    }
  }
}
</script>
