<template>
    <details class="details">
      <summary>{{title}}</summary>
     <div class="details__content"><slot>{{content}}</slot></div>
    </details>
</template>

<script>
export default {
  name: 'BaseDetails',
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  }
}
</script>
