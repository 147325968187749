<template>
  <base-section class="section-reviews"
    :title="$t('PORTAL.SECTION.REVIEWS.HEADER')">
    <template v-slot:section-background>
      <base-background-item-blot/>
      <base-background-item-ellipse is-green/>
      <base-background-item-square/>
      <base-background-item-triangle is-green/>
      <base-background-item-oval is-light-blue/>
    </template>

    <base-tabs
      :tabs-tap="tabTaps"
      v-model="currentTab"
      i18n-navigation-item-template="PORTAL.SECTION.REVIEWS.TAB.${item}.VALUE"
    >
      <base-tab-item
        class="reviews__institutions-a-service"
        key="reviews__institutions-a-service"
        v-show="currentTab === tabTaps.INSTITUTIONS_ABOUT_SERVICE"
      >
        <base-loader v-if="currentLoading(tabTaps.INSTITUTIONS_ABOUT_SERVICE)"/>
        <section-reviews-list
          v-else
          :is-displayed="currentTab === tabTaps.INSTITUTIONS_ABOUT_SERVICE"
          :list="getList"/>
      </base-tab-item>

      <base-tab-item
        class="reviews__teacher-a-service"
        key="reviews__teacher-a-service"
        v-show="currentTab === tabTaps.TEACHERS_ABOUT_SERVICE"
      >
        <base-loader v-if="currentLoading(tabTaps.TEACHERS_ABOUT_SERVICE)"/>
        <section-reviews-list
          v-else
          :is-displayed="currentTab === tabTaps.TEACHERS_ABOUT_SERVICE"
          :list="getList"/>
      </base-tab-item>

      <base-tab-item
        class="reviews__parent-a-institutions"
        key="reviews__parent-a-institutions"
        v-show="currentTab === tabTaps.PARENTS_ABOUT_INSTITUTIONS"
      >
        <base-loader v-if="currentLoading(tabTaps.PARENTS_ABOUT_INSTITUTIONS)"/>
        <section-reviews-list
          v-else
          :is-displayed="currentTab === tabTaps.PARENTS_ABOUT_INSTITUTIONS"
          :list="getList"/>
      </base-tab-item>

    </base-tabs>
  </base-section>
</template>

<script>
import BaseBackgroundItemBlot from '@/components/base/background/item/BaseBackgroundItemBlot'
import BaseBackgroundItemEllipse from '@/components/base/background/item/BaseBackgroundItemEllipse'
import BaseBackgroundItemOval from '@/components/base/background/item/BaseBackgroundItemOval'
import BaseBackgroundItemSquare from '@/components/base/background/item/BaseBackgroundItemSquare'
import BaseBackgroundItemTriangle from '@/components/base/background/item/BaseBackgroundItemTriangle'
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseSection from '@/components/base/section/BaseSection'
import BaseTabItem from '@/components/base/tabs/BaseTabItem'
import BaseTabs from '@/components/base/tabs/BaseTabs'
import SectionReviewsList from '@/components/section/reviews/SectionReviewsList'
import {
  SECTION_REVIEWS_TYPE,
  REVIEWS_MUTATION_SET_SELECTED_TYPE,
  REVIEWS_ACTION_FETCH, REVIEWS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE, REVIEWS_GETTER_GET_SECTION_LOADER
} from '@/store/modules/reviews/reviews.constant'
import { STORE_PATH_REVIEWS } from '@/store/store.list'
import { mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  name: 'SectionReviews',
  components: {
    BaseSection,
    SectionReviewsList,
    BaseLoader,
    BaseTabItem,
    BaseTabs,
    BaseBackgroundItemBlot,
    BaseBackgroundItemOval,
    BaseBackgroundItemTriangle,
    BaseBackgroundItemSquare,
    BaseBackgroundItemEllipse
  },
  data () {
    return {
      currentTab: SECTION_REVIEWS_TYPE.INSTITUTIONS_ABOUT_SERVICE,
      tabTaps: SECTION_REVIEWS_TYPE
    }
  },
  mounted () {
    this.getItems(this.currentTab)
  },
  watch: {
    currentTab (newValue) {
      this[REVIEWS_MUTATION_SET_SELECTED_TYPE](newValue)
      this.getItems(newValue)
    }
  },
  computed: {
    ...mapGetters(STORE_PATH_REVIEWS, {
      getList: REVIEWS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE,
      currentLoading: REVIEWS_GETTER_GET_SECTION_LOADER
    })
  },
  methods: {
    ...mapMutations(STORE_PATH_REVIEWS, [REVIEWS_MUTATION_SET_SELECTED_TYPE]),
    ...mapActions(STORE_PATH_REVIEWS, [REVIEWS_ACTION_FETCH]),
    async getItems (type) {
      if (this.getList.length === 0) {
        await this[REVIEWS_ACTION_FETCH]({
          type,
          page: 1
        })
      }
    }
  }
}
</script>
