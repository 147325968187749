<template>
  <base-section class="section-documents"
                :title="$t('PORTAL.SECTION.DOCUMENTS.HEADER')">
    <template v-slot:section-background>
      <base-background-item-square/>
      <base-background-item-triangle is-green/>
    </template>
    <base-tabs
      :tabs-tap="tabTaps"
      v-model="currentTab"
      i18n-navigation-item-template="PORTAL.SECTION.DOCUMENTS.TAB.${item}.VALUE"
    >
      <base-tab-item class="events__institutions"
                     key="event_institutions"
                     v-show="currentTab === tabTaps.EDUCATION_INSTITUTIONS">

        <base-loader v-if="currentLoading(tabTaps.EDUCATION_INSTITUTIONS)"/>
        <section-documents-list
          v-else
          :is-displayed="currentTab===tabTaps.EDUCATION_INSTITUTIONS"
          :list="getList"/>

      </base-tab-item>

      <base-tab-item class="events__teacher"
                     key="event-Teacher"
                     v-show="currentTab === tabTaps.TEACHERS">
        <base-loader v-if="currentLoading(tabTaps.TEACHERS)"/>
        <section-documents-list
          v-else
          :is-displayed="currentTab===tabTaps.TEACHERS"
          :list="getList"/>
      </base-tab-item>
    </base-tabs>
  </base-section>
</template>

<script>
import BaseBackgroundItemSquare from '@/components/base/background/item/BaseBackgroundItemSquare'
import BaseBackgroundItemTriangle from '@/components/base/background/item/BaseBackgroundItemTriangle'
import BaseLoader from '@/components/base/loader/BaseLoader'
import BaseSection from '@/components/base/section/BaseSection'
import BaseTabItem from '@/components/base/tabs/BaseTabItem'
import BaseTabs from '@/components/base/tabs/BaseTabs'
import SectionDocumentsList from '@/components/section/documents/SectionDocumentsList'
import {
  SECTION_DOCUMENTS_TYPE,
  DOCUMENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE,
  DOCUMENTS_GETTER_GET_SECTION_LOADER,
  DOCUMENTS_MUTATION_SET_SELECTED_TYPE, DOCUMENTS_ACTION_FETCH
} from '@/store/modules/documents/documents.constant'
import { STORE_PATH_DOCUMENTS } from '@/store/store.list'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'SectionDocuments',
  components: {
    BaseSection,
    SectionDocumentsList,
    BaseLoader,
    BaseTabItem,
    BaseTabs,
    BaseBackgroundItemSquare,
    BaseBackgroundItemTriangle
  },
  data () {
    return {
      currentTab: SECTION_DOCUMENTS_TYPE.EDUCATION_INSTITUTIONS,
      tabTaps: SECTION_DOCUMENTS_TYPE
    }
  },
  mounted () {
    this.getItems(this.currentTab)
  },
  computed: {
    ...mapGetters(STORE_PATH_DOCUMENTS, {
      getList: DOCUMENTS_GETTER_GET_LIMITED_LIST_BY_SELECTED_TYPE,
      currentLoading: DOCUMENTS_GETTER_GET_SECTION_LOADER
    })
  },
  watch: {
    currentTab (newValue) {
      this[DOCUMENTS_MUTATION_SET_SELECTED_TYPE](newValue)
      this.getItems(newValue)
    }
  },
  methods: {
    ...mapMutations(STORE_PATH_DOCUMENTS, [DOCUMENTS_MUTATION_SET_SELECTED_TYPE]),
    ...mapActions(STORE_PATH_DOCUMENTS, [DOCUMENTS_ACTION_FETCH]),
    async getItems (type) {
      if (this.getList.length === 0) {
        await this[DOCUMENTS_ACTION_FETCH]({
          type,
          page: 1
        })
      }
    }
  }
}
</script>
