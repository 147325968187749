<template>
    <div class="document">
      <div class="document__logo">
        <base-icon-file v-if="getUrl"
                        :name="getIconName"
                        :data-type="item.dataType"
                       />
      </div>
      <div class="document__content">
        <div class="document-content__title"
             :title="item.name">
          {{item.name}}
        </div>
        <div class="document-content__footer file">
          <time class="hint file__published">
            <base-plain-date
              :value="item.dtCreated"
              :format="getDateFormat"/>
          </time>
          <span class="file__size">{{getPluralisationFileSize}}</span>
          <base-button
            class="file__link"
            :href="getUrl"
            :value="$t('PORTAL.DOCUMENT.LINK.VALUE')"
            target="_blank"
            is-link
          />
        </div>
      </div>
    </div>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import BaseIconFile from '@/components/base/icon/BaseIconFile'
import BasePlainDate from '@/components/base/plainDate/BasePlainDate'
import { C_DOCUMENT_TYPE_DOU } from '@/lib/constant/c.document'
import {
  C_LOCALS_DATE_FORMAT_TYPE,
  C_LOCALS_FILE_SIZE_PLURALISATION_TYPE,
  C_LOCALS_FILE_SIZE_PLURALISATION
} from '@/lib/constant/c.locales'
import { C_MATERIAL_DATA_TYPE } from '@/lib/constant/c.material'
import { getComputedMaterialIcon } from '@/lib/utils/material/utilsMaterial'
export default {
  name: 'BaseDocument',
  components: { BasePlainDate, BaseIconFile, BaseButton },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: undefined,
          dtCreated: undefined,
          dtPublished: undefined,
          file: {
            id: undefined,
            url: undefined,
            size: undefined,
            contentType: undefined
          }
        }
      }
    },
    type: {
      type: String,
      default: C_DOCUMENT_TYPE_DOU
    }
  },
  computed: {
    getIconName () {
      const dataType = this.type === C_DOCUMENT_TYPE_DOU ? C_MATERIAL_DATA_TYPE.document : this.item?.dataType
      return getComputedMaterialIcon(dataType, this?.item?.file?.url)
    },
    getPluralisationFileSize () {
      let pluralisationType = C_LOCALS_FILE_SIZE_PLURALISATION_TYPE.B
      let fileSize = this.item?.file?.size
      if (!fileSize) {
        return ''
      }
      if (fileSize >= 1024) {
        fileSize = fileSize / 1024
        pluralisationType = C_LOCALS_FILE_SIZE_PLURALISATION_TYPE.KB
      }
      if (fileSize >= 1024) {
        fileSize = fileSize / 1024
        pluralisationType = C_LOCALS_FILE_SIZE_PLURALISATION_TYPE.MB
      }
      if (fileSize >= 1024) {
        fileSize = fileSize / 1024
        pluralisationType = C_LOCALS_FILE_SIZE_PLURALISATION_TYPE.GB
      }

      fileSize = Math.round((fileSize + Number.EPSILON) * 100) / 100
      return this.$t(C_LOCALS_FILE_SIZE_PLURALISATION(pluralisationType), { n: fileSize })
    },
    getUrl () {
      return this.item?.file?.url
    },
    getDateFormat () {
      return C_LOCALS_DATE_FORMAT_TYPE.numericShort
    }
  }
}
</script>
