<template>
    <base-swiper
      is-button
      @button-click="handleSwiperButtonClick"
      :is-displayed="true">
        <base-swiper-slide v-for="item in list"
                           :key="item.member.id">
          <section-teachers-list-item
            :value="item"/>
        </base-swiper-slide>
    </base-swiper>
</template>

<script>
import BaseSwiper from '@/components/base/swiper/BaseSwiper'
import BaseSwiperSlide from '@/components/base/swiper/BaseSwiperSlide'
import SectionTeachersListItem from '@/components/section/teachers/SectionTeachersListItem'
export default {
  name: 'SectionTeachersList',
  components: { SectionTeachersListItem, BaseSwiperSlide, BaseSwiper },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    handleSwiperButtonClick () {
      console.log('test')
    }

  }
}
</script>
