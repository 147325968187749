import {
  MODALS_COMMON_GETTER_GET_MODAL_NAME,
  MODALS_COMMON_GETTER_GET_IS_OPEN, MODALS_COMMON_MUTATION_SET_OPEN
} from '@/store/modules/modals/common/modalCommon.constant'
import {
  MODALS_MUTATION_TOGGLE_OPEN,
  MODALS_GETTERS_IS_NESTED,
  MODALS_GETTERS_IS_OPEN
} from '@/store/modules/modals/modals.constant'
import { STORE_PATH_MODALS } from '@/store/store.list'
import { mapMutations, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(STORE_PATH_MODALS, {
      _isNested: MODALS_GETTERS_IS_NESTED,
      _isOpened: MODALS_GETTERS_IS_OPEN
    }),
    mixinModals_getModalName () {
      return this[MODALS_COMMON_GETTER_GET_MODAL_NAME]
    },
    mixinModals_isOpen () {
      return this[MODALS_COMMON_GETTER_GET_IS_OPEN]
    }
  },
  watch: {
    mixinModals_isOpen (newValue) {
      this._mixinModal_toggleModal(newValue)
    }
  },
  beforeCreate () {
    /**
     * Для работы устанавливаем нужные для текущего сторпаса коммон функции для отслеживания открытия,  отправки
     * данных в нужные стор пулы.
     */
    if (typeof this.$options.modalStorePath === 'undefined') {
      throw new Error(`При использовании миксина mixinModals.js в компоненте ${this.$options.name} нне установлена опция modalStorePath`)
    }
    this.$options.computed = Object.assign(this.$options.computed, {
      ...mapGetters(this.$options.modalStorePath, [
        MODALS_COMMON_GETTER_GET_MODAL_NAME,
        MODALS_COMMON_GETTER_GET_IS_OPEN
      ])
    })
    this.$options.methods = Object.assign(this.$options.methods, {
      ...mapMutations(this.$options.modalStorePath, [MODALS_COMMON_MUTATION_SET_OPEN])
    })
  },
  methods: {
    ...mapMutations(STORE_PATH_MODALS, [MODALS_MUTATION_TOGGLE_OPEN]),
    mixinModals_handleBeforeOpened (cb = undefined) {
      if (typeof cb === 'function') {
        cb()
      }
      this[MODALS_MUTATION_TOGGLE_OPEN](true)
      this._mixinModals_documentClassWorker()
    },
    mixinModals_handleBeforeClosed (cb = undefined) {
      if (typeof cb === 'function') {
        cb()
      }
      this[MODALS_MUTATION_TOGGLE_OPEN](false)
      this.mixinModals_closeModal()
      this._mixinModals_documentClassWorker()
    },
    mixinModals_showModal () {
      this[MODALS_COMMON_MUTATION_SET_OPEN](true)
    },
    mixinModals_closeModal () {
      this[MODALS_COMMON_MUTATION_SET_OPEN](false)
    },
    _mixinModals_documentClassWorker () {
      if (this._isOpened) {
        document.documentElement.classList.add('modal--opened')
      } else {
        document.documentElement.classList.remove('modal--opened')
      }
    },
    _mixinModal_toggleModal (isOpen) {
      if (isOpen === true) {
        this.$modal.show(this.mixinModals_getModalName)
      } else {
        this.$modal.hide(this.mixinModals_getModalName)
      }
    }

  }
}
