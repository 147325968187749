<template>
  <modal
    @before-open="mixinModals_handleBeforeOpened"
    @before-close="mixinModals_handleBeforeClosed(beforeClose)"
    :name="mixinModals_getModalName"
    width="100%"
    :adaptive="true"
    :max-width="640"
    :scrollable="true"
    height="auto"
    class="modal-institution"
  >
    <div class="modal-institution__container modal-institution__container--loader"
         v-if="isLoading">
      <base-loader/>
    </div>
    <div class="modal-institution__container " v-else>
      <div class="modal-institutions__close">
        <base-button @click="mixinModals_closeModal"
                     is-link
        >x
        </base-button>
      </div>
      <div class="modal-institutions__header">

        <h5 class="modal-institutions-header__title">{{getTitle}}</h5>
        <div class="modal-institutions-header__logo"
          :style="getLogoBgStyle"
        >
          <img :src="getLogo"
               alt=""
               ref="logo-image"
               v-show="getLogo">
        </div>
        <div class="modal-institutions-header__contacts"
             v-show="contactsPhone">
          <base-contact-list
            :is-icon="false"
            :contacts="contactsPhone"/>
        </div>
      </div>

      <div class="modal-institutions__content">
        <p>{{getAbout}}</p>
        <address>{{getRawAddress}}</address>
      </div>
      <div class="modal-institutions__footer">
        <div class="contact__email">
          <span>{{$t('PORTAL.MODALS.INSTITUTIONS.FOOTER.EMAIL-ADDRESS')}}</span>
          <base-contact-item :is-icon="false"
                             :contact="getEmail"></base-contact-item>
        </div>
        <div>
          <base-button is-link
                       :href="getUrl"
                       target="_blank">{{$t('PORTAL.MODALS.INSTITUTIONS.FOOTER.BUTTON.GO-TO-THE-SITE')}}</base-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import BaseContactItem from '@/components/base/contact/BaseContactItem'
import BaseContactList from '@/components/base/contact/BaseContactList'
import BaseLoader from '@/components/base/loader/BaseLoader'
import mixinModals from '@/components/mixin/mixinModals'
import { C_EVENTS_MODAL_INSTITUTION_OPEN } from '@/lib/constant/c.events'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  MODALS_INSTITUTION_MUTATION_CLEAR_ACTIVE_ITEM,
  MODALS_INSTITUTION_ACTION_FETCH_BY_ID,
  MODALS_INSTITUTION_GETTER_GET_ACTIVE_ITEM,
  MODALS_INSTITUTION_GETTER_GET_CONTACTS_PHONES,
  MODALS_INSTITUTION_GETTER_GET_TITLE,
  MODALS_INSTITUTION_GETTER_GET_LOGO,
  MODALS_INSTITUTION_GETTER_GET_RAW_ADDRESS,
  MODALS_INSTITUTION_GETTER_GET_ABOUT,
  MODALS_INSTITUTION_GETTER_GET_ACTIVE_EMAIL, MODALS_INSTITUTION_GETTER_GET_URL
} from '@/store/modules/modals/institution/modalsInstitutions.constant'
import { STORE_PATH_MODALS_INSTITUTION } from '@/store/store.list'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { eventBus } from '@/lib/utils/eventBus'
import FastAverageColor from 'fast-average-color'

export default {
  name: 'ModalInstitution',
  components: {
    BaseContactItem,
    BaseContactList,
    BaseLoader,
    BaseButton
  },
  modalStorePath: STORE_PATH_MODALS_INSTITUTION,
  mixins: [mixinModals],
  data () {
    return {
      logoBgColor: undefined
    }
  },
  created () {
    eventBus.on(C_EVENTS_MODAL_INSTITUTION_OPEN, this.handleModalOpen)
  },
  destroyed () {
    eventBus.off(C_EVENTS_MODAL_INSTITUTION_OPEN, this.handleModalOpen)
  },
  computed: {
    ...mapGetters(STORE_PATH_MODALS_INSTITUTION, [
      COMMON_LOADER_GETTER_IS_LOADING,
      MODALS_INSTITUTION_GETTER_GET_ACTIVE_ITEM,
      MODALS_INSTITUTION_GETTER_GET_CONTACTS_PHONES,
      MODALS_INSTITUTION_GETTER_GET_TITLE,
      MODALS_INSTITUTION_GETTER_GET_LOGO,
      MODALS_INSTITUTION_GETTER_GET_RAW_ADDRESS,
      MODALS_INSTITUTION_GETTER_GET_ABOUT,
      MODALS_INSTITUTION_GETTER_GET_ACTIVE_EMAIL,
      MODALS_INSTITUTION_GETTER_GET_URL
    ]),
    isLoading () {
      return this[COMMON_LOADER_GETTER_IS_LOADING]
    },
    contactsPhone () {
      return this[MODALS_INSTITUTION_GETTER_GET_CONTACTS_PHONES]
    },
    getTitle () {
      return this[MODALS_INSTITUTION_GETTER_GET_TITLE]
    },
    getLogo () {
      return this[MODALS_INSTITUTION_GETTER_GET_LOGO]
    },
    getLogoBgStyle () {
      if (this.logoBgColor) {
        return { 'background-color': this.logoBgColor }
      } else {
        return {}
      }
    },
    getUrl () {
      return this[MODALS_INSTITUTION_GETTER_GET_URL]
    },
    getEmail () {
      return this[MODALS_INSTITUTION_GETTER_GET_ACTIVE_EMAIL]
    },
    getRawAddress () {
      return this[MODALS_INSTITUTION_GETTER_GET_RAW_ADDRESS]
    },
    getAbout () {
      return this[MODALS_INSTITUTION_GETTER_GET_ABOUT]
    }
  },
  methods: {
    ...mapActions(STORE_PATH_MODALS_INSTITUTION, [MODALS_INSTITUTION_ACTION_FETCH_BY_ID]),
    ...mapMutations(STORE_PATH_MODALS_INSTITUTION, [MODALS_INSTITUTION_MUTATION_CLEAR_ACTIVE_ITEM]),
    async getAverageColor () {
      const fastAverageColor = new FastAverageColor()
      const container = this.$refs['logo-image']
      const color = await fastAverageColor.getColorAsync(container)
      this.logoBgColor = color?.rgba
    },
    beforeClose () {
      this[MODALS_INSTITUTION_MUTATION_CLEAR_ACTIVE_ITEM]()
    },
    async handleModalOpen (id) {
      this.mixinModals_showModal()
      await this[MODALS_INSTITUTION_ACTION_FETCH_BY_ID](id)
      await this.getAverageColor()
    }
  }
}
</script>
