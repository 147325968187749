<template>
  <swiper-slide>
    <slot></slot>
  </swiper-slide>
</template>

<script>
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import { Swiper as SwiperClass } from 'swiper/swiper.esm.js'
const { SwiperSlide } = getAwesomeSwiper(SwiperClass)
export default {
  name: 'BaseSwiperSlide',
  components: { SwiperSlide }
}
</script>
